

export const contractConfigs = {
	"generic": {
		"abi": require("../abis/genABI-dai.json"),
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D"
	},
	"chef": {
		"abi": require("../abis/chef.json"),
		"address": "0x3c559c90fD1c7a73D6EaC8510B8Fb16Ca333226F"
	}
}

export const poolConfigs = {
	"0": {
		"stake-name": "gdeli",
		"fee": "0",
		"name": "gDELI",
		"rate": "50",
		"dex": "zoo",
		"action": "buy",
		"status": "open"
		}, 
	"1": {
		"stake-name": "acre",
		"fee": "0",
		"name": "ACRE",
		"rate": "50",
		"dex": "none",
		"action": "none",
		"status": "open"
	}, 
	"2": {
		"stake-name": "echo",
		"fee": "0",
		"name": "ECHO",
		"rate": "50",
		"dex": "zoo",
		"action": "buy",
		"status": "open"
	}, 
	"3": {
		"stake-name": "ftmGdefHyperLP",
		"fee": "0",
		"name": "FTM-GIGADEFLATON HLP",
		"rate": "25",
		"dex": "hyper",
		"action": "add",
		"pairA": "FTM",
		"pairB": "0xb0a3bf98516d6ce466d2f78bd6a494ef8ffb396d",
		"status": "open"

	}, 
	"4": {
		"stake-name": "ftmDefHyperLP",
		"fee": "0",
		"name": "FTM-DEFLATON HLP",
		"rate": "25",
		"dex": "hyper",
		"action": "add",
		"pairA": "FTM",
		"pairB": "0xfd3b077dc97915ab3a49db1b1021c13ad55751dc",
		"status": "open"


	}, 
	"5": {
		"stake-name": "ftmGdefSushiLP",
		"fee": "0",
		"name": "FTM-GIGADEFLATON SushiLP",
		"rate": "25",
		"dex": "sushi",
		"action": "add",
		"pairA": "ETH",
		"pairB": "0xb0a3bf98516d6ce466d2f78bd6a494ef8ffb396d",
		"status": "open"
	}, 
	"6": {
		"stake-name": "ftmDefSushiLP",
		"fee": "0",
		"name": "FTM-DEFLATON SushiLP",
		"rate": "25",
		"dex": "sushi",
		"action": "add",
		"pairA": "ETH",
		"pairB": "0xfd3b077dc97915ab3a49db1b1021c13ad55751dc",
		"status": "open"
	}, 
	"7": {
		"stake-name": "catnip",
		"fee": "0",
		"name": "CATNIP",
		"rate": "0",
		"dex": "zoo",
		"action": "buy",
		"status": "closed"
	}, 
	"8": {
		"stake-name": "ftmHodlZooLP",
		"fee": "0",
		"name": "FTM-HODL ZOO LP",
		"rate": "0",
		"dex": "zoo",
		"action": "add",
		"pairA": "ETH",
		"pairB": "0xB2Da66c4a89D0f93935D1efDB9C9c8D1d3Ba9343",
		"status": "closed"
	},
	"9": {
		"stake-name": "ftmGdeliZooLP",
		"fee": "0",
		"name": "FTM-GDELI ZOO LP",
		"rate": "0",
		"dex": "zoo",
		"action": "add",
		"pairA": "ETH",
		"pairB": "0xfa1807bd24a438b389ca33072375dfbba74dfaab",
		"status": "closed"
	},
	"10": {
		"stake-name": "zoo",
		"fee": "0",
		"name": "ZOO",
		"rate": "0",
		"dex": "zoo",
		"action": "buy",
		"status": "closed"
	},
	"11": {
		"stake-name": "ftmEchoZooLP",
		"fee": "0",
		"name": "FTM-ECHO ZLP",
		"rate": "0",
		"dex": "zoo",
		"action": "add",
		"status": "closed"
	},
	"12": {
		"stake-name": "foo",
		"fee": "0",
		"name": "FOO",
		"rate": "0",
		"dex": "sushi",
		"action": "buy",
		"status": "closed"
	},
	"13": {
		"stake-name": "ftmAnimalZooLP",
		"fee": "0",
		"name": "FTM-ANIMAL ZLP",
		"rate": "0",
		"dex": "zoo",
		"action": "buy",
		"status": "closed"
	},
	"14": {
		"stake-name": "ftmAcresZooLP",
		"fee": "0",
		"name": "FTM-ACRE ZLP",
		"rate": "0",
		"dex": "zoo",
		"action": "add",
		"pairA": "ETH",
		"pairB": "0x12245af2d6d298D98019C15FB1001D8712D27A7D",
		"status": "closed"
	},
	"15": {
		"stake-name": "bomb",
		"fee": "0",
		"name": "BOMB",
		"rate": "0",
		"dex": "zoo",
		"action": "buy",
		"status": "closed"
	},
	
	"16": {
		"stake-name": "ftmGdeliSpirLP",
		"fee": "0",
		"name": "FTM-gDELI Spirit LP",
		"rate": "100",
		"dex": "spirit",
		"action": "add",
		"status": "open"
	},
	"17": {
		"stake-name": "ftmAcresSpirLP",
		"fee": "0",
		"name": "FTM-ACRE Spirit LP",
		"rate": "100",
		"dex": "spirit",
		"action": "add",
		"status": "open"
	},
	"18": {
		"stake-name": "ftmEchoSpirLP",
		"fee": "0",
		"name": "FTM-ECHO Spirit LP",
		"rate": "100",
		"dex": "spirit",
		"action": "add",
		"status": "open"
	}

}

export const rewardToken = {
	"name": "acre",
	"displayname": "ACRE",
	"symbol": "ACRE",
	"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D",
	"icon": require("../images/token-icons/acre128.png")
}

export const tokenConfigs = {
	"acre": {
		"name": "ACRE",
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D",
		"icon": require("../images/token-icons/acre128.png"),
		"decimals": 18
	},
	"gdeli": {
		"name": "gDELI",
		"address": "0xfa1807bd24a438b389ca33072375dfbba74dfaab",
		"icon": require("../images/token-icons/gdeli.png"),
		"decimals": 9
	},
	"echo": {
		"name": "ECHO",
		"address": "0x54477a1d1bb8c1139eef754fd2efd4ddee7933dd",
		"icon": require("../images/token-icons/echo128.png"),
		"decimals": 9
	},
	"ftmGdefHyperLP": {
		"name": "FTM-GIGADEFLATON HLP",
		"address": "0xa8f8ce6959d70dc699d6d603319353547daa3eae",
		"icon": require("../images/token-icons/gigadeflatonftmHLP.png"),
		"decimals": 18
	},
	"ftmDefHyperLP": {
		"name": "FTM-DEFLATON HLP",
		"address": "0x925216d9d3277eb05b009e852be6e37d253d83c6",
		"icon": require("../images/token-icons/deflatonftmHLP.png"),
		"decimals": 18
	},
	"ftmGdefSushiLP": {
		"name": "FTM-GIGADEFLATON SushiLP",
		"address": "0x24623f2a4ec70e81862469fbab10900b51389df1",
		"icon": require("../images/token-icons/gigadeflatonftmSLP.png"),
		"decimals": 18
	},
	"ftmDefSushiLP": {
		"name": "FTM-DEFLATON SushiLP",
		"address": "0x0281596a54d2876e827dc2eeb2102210f204decd",
		"icon": require("../images/token-icons/deflatonftmSLP.png"),
		"decimals": 18
	},
	"catnip": {
		"name": "CATNIP",
		"address": "0xBbd946041824416A4e2EB37d5880718B3812c9Cb",
		"icon": require("../images/token-icons/catnip.png"),
		"decimals": 18
	},
	"ftmHodlZooLP": {
		"name": "FTM-HODL ZooLP",
		"address": "0xdd9ef5358e54faef385fcf27be2ef4b2de469ac4",
		"icon": require("../images/token-icons/hodlftmZLP.png"),
		"decimals": 18
	},
	"ftmGdeliZooLP": {
		"name": "FTM-GDELI ZooLP",
		"address": "0xc2a826d7732bbb5c33768a233de05e78ae274d00",
		"icon": require("../images/token-icons/ftmgdeliZLP.png"),
		"decimals": 18
	},
	"zoo": {
		"name": "Zoo",
		"address": "0x09e145a1d53c0045f41aeef25d8ff982ae74dd56",
		"icon": require("../images/token-icons/ZOO.png"), //./images/token-icons/matic.png"
		"decimals": 0
	},
	"ftmEchoZooLP": {
		"name": "FTM-ECHO ZooLP",
		"address": "0x79d25d00C159BAf1bdE8831B00e2f7370Bf5Fe46",
		"icon": require("../images/token-icons/ftmechoZLP.png"),
		"decimals": 18
	},
	"foo": {
		"name": "FOO",
		"address": "0xfbc3c04845162f067a0b6f8934383e63899c3524",
		"icon": require("../images/token-icons/foo.png"),
		"decimals": 18
	},
	"ftmAnimalZooLP": {
		"name": "FTM-ANIMAL ZLP",
		"address": "0x9d4285681eeba3d83dbbca2dde19cf592a1bc02e",
		"icon": require("../images/token-icons/ftmanimalzlp.png"),
		"decimals": 18
	},
	"ftmAcresZooLP": {
		"name": "FTM-ACRE ZLP",
		"address": "0xe8dfe2191bb1fe797dfdc747223c440a19bfdac4",
		"icon": require("../images/token-icons/ftmacreszlp.png"),
		"decimals": 18
	},
	"bomb": {
		"name": "BOMB",
		"address": "0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378",
		"icon": require("../images/token-icons/bomb.png"),
		"decimals": 0
	},
	"ftmEchoSpirLP": {
		"name": "FTM-ECHO Spirit LP",
		"address": "0x26c2be4dff687504faf0f45b35149ed1288b40a4",
		"icon": require("../images/token-icons/ftmechospiritlp.png"),
		"decimals": 18
	},
	"ftmGdeliSpirLP": {
		"name": "FTM-gDELI Spirit LP",
		"address": "0x2cbbfde25dedbba0bd2d07206dcdd4194fdbcc90",
		"icon": require("../images/token-icons/ftmgdelispiritlp.png"),
		"decimals": 18
	},
	"ftmAcresSpirLP": {
		"name": "FTM-ACRE Spir LP",
		"address": "0x63594b5011d9d7e2ed54f35f3db6ce797688ed1d",
		"icon": require("../images/token-icons/ftmacreszlp.png"),
		"decimals": 18
	},
}